if(!window.ratVec){window.ratVec = {};}
if(!window.ratVec.add){window.ratVec.add = {};}

(function (global, module, exports) {
'use strict'

var bnadd = window.bigRat.add

module.exports = add

function add (a, b) {
  var n = a.length
  var r = new Array(n)
  for (var i=0; i<n; ++i) {
    r[i] = bnadd(a[i], b[i])
  }
  return r
}

}).call(this, window, { get exports() { if(!window.ratVec.add) window.ratVec.add = {}; return window.ratVec.add; }, set exports(v) { if(typeof window.ratVec.add === "object") { for (var k in window.ratVec.add) { v[k] = window.ratVec.add[k]; } } window.ratVec.add = v; } }, window.ratVec.add);