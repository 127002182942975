if(!window.bigRat){window.bigRat = {};}
if(!window.bigRat.sub){window.bigRat.sub = {};}

(function (global, module, exports) {
'use strict'

var rationalize = window.bigRat.lib.rationalize

module.exports = sub

function sub(a, b) {
  return rationalize(a[0].mul(b[1]).sub(a[1].mul(b[0])), a[1].mul(b[1]))
}

}).call(this, window, { get exports() { if(!window.bigRat.sub) window.bigRat.sub = {}; return window.bigRat.sub; }, set exports(v) { if(typeof window.bigRat.sub === "object") { for (var k in window.bigRat.sub) { v[k] = window.bigRat.sub[k]; } } window.bigRat.sub = v; } }, window.bigRat.sub);