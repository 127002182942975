if(!window.bigRat){window.bigRat = {};}
if(!window.bigRat.lib){window.bigRat.lib = {};}
if(!window.bigRat.lib.isBn){window.bigRat.lib.isBn = {};}

(function (global, module, exports) {
'use strict'

var BN = window.bnjs.lib.bnjs

module.exports = isBN

//Test if x is a bignumber
//FIXME: obviously this is the wrong way to do it
function isBN(x) {
  return x && typeof x === 'object' && Boolean(x.words)
}

}).call(this, window, { get exports() { if(!window.bigRat.lib.isBn) window.bigRat.lib.isBn = {}; return window.bigRat.lib.isBn; }, set exports(v) { if(typeof window.bigRat.lib.isBn === "object") { for (var k in window.bigRat.lib.isBn) { v[k] = window.bigRat.lib.isBn[k]; } } window.bigRat.lib.isBn = v; } }, window.bigRat.lib.isBn);