if(!window.bigRat){window.bigRat = {};}
if(!window.bigRat.lib){window.bigRat.lib = {};}
if(!window.bigRat.lib.numToBn){window.bigRat.lib.numToBn = {};}

(function (global, module, exports) {
'use strict'

var BN = window.bnjs.lib.bnjs
var db = window.doubleBits

module.exports = num2bn

function num2bn(x) {
  var e = db.exponent(x)
  if(e < 52) {
    return new BN(x)
  } else {
    return (new BN(x * Math.pow(2, 52-e))).ushln(e-52)
  }
}

}).call(this, window, { get exports() { if(!window.bigRat.lib.numToBn) window.bigRat.lib.numToBn = {}; return window.bigRat.lib.numToBn; }, set exports(v) { if(typeof window.bigRat.lib.numToBn === "object") { for (var k in window.bigRat.lib.numToBn) { v[k] = window.bigRat.lib.numToBn[k]; } } window.bigRat.lib.numToBn = v; } }, window.bigRat.lib.numToBn);