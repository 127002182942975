if(!window.bigRat){window.bigRat = {};}
if(!window.bigRat.lib){window.bigRat.lib = {};}
if(!window.bigRat.lib.bnSign){window.bigRat.lib.bnSign = {};}

(function (global, module, exports) {
'use strict'

var BN = window.bnjs.lib.bnjs

module.exports = sign

function sign (x) {
  return x.cmp(new BN(0))
}

}).call(this, window, { get exports() { if(!window.bigRat.lib.bnSign) window.bigRat.lib.bnSign = {}; return window.bigRat.lib.bnSign; }, set exports(v) { if(typeof window.bigRat.lib.bnSign === "object") { for (var k in window.bigRat.lib.bnSign) { v[k] = window.bigRat.lib.bnSign[k]; } } window.bigRat.lib.bnSign = v; } }, window.bigRat.lib.bnSign);