if(!window.robustScale){window.robustScale = {};}

(function (global, module, exports) {
"use strict"

var twoProduct = window.twoProduct
var twoSum = window.twoSum

module.exports = scaleLinearExpansion

function scaleLinearExpansion(e, scale) {
  var n = e.length
  if(n === 1) {
    var ts = twoProduct(e[0], scale)
    if(ts[0]) {
      return ts
    }
    return [ ts[1] ]
  }
  var g = new Array(2 * n)
  var q = [0.1, 0.1]
  var t = [0.1, 0.1]
  var count = 0
  twoProduct(e[0], scale, q)
  if(q[0]) {
    g[count++] = q[0]
  }
  for(var i=1; i<n; ++i) {
    twoProduct(e[i], scale, t)
    var pq = q[1]
    twoSum(pq, t[0], q)
    if(q[0]) {
      g[count++] = q[0]
    }
    var a = t[1]
    var b = q[1]
    var x = a + b
    var bv = x - a
    var y = b - bv
    q[1] = x
    if(y) {
      g[count++] = y
    }
  }
  if(q[1]) {
    g[count++] = q[1]
  }
  if(count === 0) {
    g[count++] = 0.0
  }
  g.length = count
  return g
}
}).call(this, window, { get exports() { if(!window.robustScale) window.robustScale = {}; return window.robustScale; }, set exports(v) { if(typeof window.robustScale === "object") { for (var k in window.robustScale) { v[k] = window.robustScale[k]; } } window.robustScale = v; } }, window.robustScale);