if(!window.cleanPslg){window.cleanPslg = {};}
if(!window.cleanPslg.lib){window.cleanPslg.lib = {};}
if(!window.cleanPslg.lib.ratSegIntersect){window.cleanPslg.lib.ratSegIntersect = {};}

(function (global, module, exports) {
'use strict'

module.exports = solveIntersection

var ratMul = window.bigRat.mul
var ratDiv = window.bigRat.div
var ratSub = window.bigRat.sub
var ratSign = window.bigRat.sign
var rvSub = window.ratVec.sub
var rvAdd = window.ratVec.add
var rvMuls = window.ratVec.muls

function ratPerp (a, b) {
  return ratSub(ratMul(a[0], b[1]), ratMul(a[1], b[0]))
}

// Solve for intersection
//  x = a + t (b-a)
//  (x - c) ^ (d-c) = 0
//  (t * (b-a) + (a-c) ) ^ (d-c) = 0
//  t * (b-a)^(d-c) = (d-c)^(a-c)
//  t = (d-c)^(a-c) / (b-a)^(d-c)

function solveIntersection (a, b, c, d) {
  var ba = rvSub(b, a)
  var dc = rvSub(d, c)

  var baXdc = ratPerp(ba, dc)

  if (ratSign(baXdc) === 0) {
    return null
  }

  var ac = rvSub(a, c)
  var dcXac = ratPerp(dc, ac)

  var t = ratDiv(dcXac, baXdc)
  var s = rvMuls(ba, t)
  var r = rvAdd(a, s)

  return r
}

}).call(this, window, { get exports() { if(!window.cleanPslg.lib.ratSegIntersect) window.cleanPslg.lib.ratSegIntersect = {}; return window.cleanPslg.lib.ratSegIntersect; }, set exports(v) { if(typeof window.cleanPslg.lib.ratSegIntersect === "object") { for (var k in window.cleanPslg.lib.ratSegIntersect) { v[k] = window.cleanPslg.lib.ratSegIntersect[k]; } } window.cleanPslg.lib.ratSegIntersect = v; } }, window.cleanPslg.lib.ratSegIntersect);