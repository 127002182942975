if(!window.twoProduct){window.twoProduct = {};}

(function (global, module, exports) {
"use strict"

module.exports = twoProduct

var SPLITTER = +(Math.pow(2, 27) + 1.0)

function twoProduct(a, b, result) {
  var x = a * b

  var c = SPLITTER * a
  var abig = c - a
  var ahi = c - abig
  var alo = a - ahi

  var d = SPLITTER * b
  var bbig = d - b
  var bhi = d - bbig
  var blo = b - bhi

  var err1 = x - (ahi * bhi)
  var err2 = err1 - (alo * bhi)
  var err3 = err2 - (ahi * blo)

  var y = alo * blo - err3

  if(result) {
    result[0] = y
    result[1] = x
    return result
  }

  return [ y, x ]
}
}).call(this, window, { get exports() { if(!window.twoProduct) window.twoProduct = {}; return window.twoProduct; }, set exports(v) { if(typeof window.twoProduct === "object") { for (var k in window.twoProduct) { v[k] = window.twoProduct[k]; } } window.twoProduct = v; } }, window.twoProduct);