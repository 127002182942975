if(!window.bigRat){window.bigRat = {};}
if(!window.bigRat.isRat){window.bigRat.isRat = {};}

(function (global, module, exports) {
'use strict'

var isBN = window.bigRat.lib.isBn

module.exports = isRat

function isRat(x) {
  return Array.isArray(x) && x.length === 2 && isBN(x[0]) && isBN(x[1])
}

}).call(this, window, { get exports() { if(!window.bigRat.isRat) window.bigRat.isRat = {}; return window.bigRat.isRat; }, set exports(v) { if(typeof window.bigRat.isRat === "object") { for (var k in window.bigRat.isRat) { v[k] = window.bigRat.isRat[k]; } } window.bigRat.isRat = v; } }, window.bigRat.isRat);