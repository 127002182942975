if(!window.ratVec){window.ratVec = {};}
if(!window.ratVec.sub){window.ratVec.sub = {};}

(function (global, module, exports) {
'use strict'

var bnsub = window.bigRat.sub

module.exports = sub

function sub(a, b) {
  var n = a.length
  var r = new Array(n)
    for(var i=0; i<n; ++i) {
    r[i] = bnsub(a[i], b[i])
  }
  return r
}

}).call(this, window, { get exports() { if(!window.ratVec.sub) window.ratVec.sub = {}; return window.ratVec.sub; }, set exports(v) { if(typeof window.ratVec.sub === "object") { for (var k in window.ratVec.sub) { v[k] = window.ratVec.sub[k]; } } window.ratVec.sub = v; } }, window.ratVec.sub);