if(!window.bigRat){window.bigRat = {};}
if(!window.bigRat.lib){window.bigRat.lib = {};}
if(!window.bigRat.lib.strToBn){window.bigRat.lib.strToBn = {};}

(function (global, module, exports) {
'use strict'

var BN = window.bnjs.lib.bnjs

module.exports = str2BN

function str2BN(x) {
  return new BN(x)
}

}).call(this, window, { get exports() { if(!window.bigRat.lib.strToBn) window.bigRat.lib.strToBn = {}; return window.bigRat.lib.strToBn; }, set exports(v) { if(typeof window.bigRat.lib.strToBn === "object") { for (var k in window.bigRat.lib.strToBn) { v[k] = window.bigRat.lib.strToBn[k]; } } window.bigRat.lib.strToBn = v; } }, window.bigRat.lib.strToBn);