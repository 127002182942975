if(!window.bigRat){window.bigRat = {};}
if(!window.bigRat.lib){window.bigRat.lib = {};}
if(!window.bigRat.lib.rationalize){window.bigRat.lib.rationalize = {};}

(function (global, module, exports) {
'use strict'

var num2bn = window.bigRat.lib.numToBn
var sign = window.bigRat.lib.bnSign

module.exports = rationalize

function rationalize(numer, denom) {
  var snumer = sign(numer)
  var sdenom = sign(denom)
  if(snumer === 0) {
    return [num2bn(0), num2bn(1)]
  }
  if(sdenom === 0) {
    return [num2bn(0), num2bn(0)]
  }
  if(sdenom < 0) {
    numer = numer.neg()
    denom = denom.neg()
  }
  var d = numer.gcd(denom)
  if(d.cmpn(1)) {
    return [ numer.div(d), denom.div(d) ]
  }
  return [ numer, denom ]
}

}).call(this, window, { get exports() { if(!window.bigRat.lib.rationalize) window.bigRat.lib.rationalize = {}; return window.bigRat.lib.rationalize; }, set exports(v) { if(typeof window.bigRat.lib.rationalize === "object") { for (var k in window.bigRat.lib.rationalize) { v[k] = window.bigRat.lib.rationalize[k]; } } window.bigRat.lib.rationalize = v; } }, window.bigRat.lib.rationalize);