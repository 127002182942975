if(!window.bigRat){window.bigRat = {};}
if(!window.bigRat.add){window.bigRat.add = {};}

(function (global, module, exports) {
'use strict'

var rationalize = window.bigRat.lib.rationalize

module.exports = add

function add(a, b) {
  return rationalize(
    a[0].mul(b[1]).add(b[0].mul(a[1])),
    a[1].mul(b[1]))
}

}).call(this, window, { get exports() { if(!window.bigRat.add) window.bigRat.add = {}; return window.bigRat.add; }, set exports(v) { if(typeof window.bigRat.add === "object") { for (var k in window.bigRat.add) { v[k] = window.bigRat.add[k]; } } window.bigRat.add = v; } }, window.bigRat.add);