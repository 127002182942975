if(!window.nextafter){window.nextafter = {};}

(function (global, module, exports) {
"use strict"

var doubleBits = window.doubleBits

var SMALLEST_DENORM = Math.pow(2, -1074)
var UINT_MAX = (-1)>>>0

module.exports = nextafter

function nextafter(x, y) {
  if(isNaN(x) || isNaN(y)) {
    return NaN
  }
  if(x === y) {
    return x
  }
  if(x === 0) {
    if(y < 0) {
      return -SMALLEST_DENORM
    } else {
      return SMALLEST_DENORM
    }
  }
  var hi = doubleBits.hi(x)
  var lo = doubleBits.lo(x)
  if((y > x) === (x > 0)) {
    if(lo === UINT_MAX) {
      hi += 1
      lo = 0
    } else {
      lo += 1
    }
  } else {
    if(lo === 0) {
      lo = UINT_MAX
      hi -= 1
    } else {
      lo -= 1
    }
  }
  return doubleBits.pack(lo, hi)
}
}).call(this, window, { get exports() { if(!window.nextafter) window.nextafter = {}; return window.nextafter; }, set exports(v) { if(typeof window.nextafter === "object") { for (var k in window.nextafter) { v[k] = window.nextafter[k]; } } window.nextafter = v; } }, window.nextafter);