if(!window.ratVec){window.ratVec = {};}

(function (global, module, exports) {
'use strict'

module.exports = float2rat

var rat = window.bigRat

function float2rat(v) {
  var result = new Array(v.length)
  for(var i=0; i<v.length; ++i) {
    result[i] = rat(v[i])
  }
  return result
}

}).call(this, window, { get exports() { if(!window.ratVec) window.ratVec = {}; return window.ratVec; }, set exports(v) { if(typeof window.ratVec === "object") { for (var k in window.ratVec) { v[k] = window.ratVec[k]; } } window.ratVec = v; } }, window.ratVec);