if(!window.robustSegmentIntersect){window.robustSegmentIntersect = {};}

(function (global, module, exports) {
"use strict"

module.exports = segmentsIntersect

var orient = window.robustOrientation[3]

function checkCollinear(a0, a1, b0, b1) {

  for(var d=0; d<2; ++d) {
    var x0 = a0[d]
    var y0 = a1[d]
    var l0 = Math.min(x0, y0)
    var h0 = Math.max(x0, y0)    

    var x1 = b0[d]
    var y1 = b1[d]
    var l1 = Math.min(x1, y1)
    var h1 = Math.max(x1, y1)    

    if(h1 < l0 || h0 < l1) {
      return false
    }
  }

  return true
}

function segmentsIntersect(a0, a1, b0, b1) {
  var x0 = orient(a0, b0, b1)
  var y0 = orient(a1, b0, b1)
  if((x0 > 0 && y0 > 0) || (x0 < 0 && y0 < 0)) {
    return false
  }

  var x1 = orient(b0, a0, a1)
  var y1 = orient(b1, a0, a1)
  if((x1 > 0 && y1 > 0) || (x1 < 0 && y1 < 0)) {
    return false
  }

  //Check for degenerate collinear case
  if(x0 === 0 && y0 === 0 && x1 === 0 && y1 === 0) {
    return checkCollinear(a0, a1, b0, b1)
  }

  return true
}
}).call(this, window, { get exports() { if(!window.robustSegmentIntersect) window.robustSegmentIntersect = {}; return window.robustSegmentIntersect; }, set exports(v) { if(typeof window.robustSegmentIntersect === "object") { for (var k in window.robustSegmentIntersect) { v[k] = window.robustSegmentIntersect[k]; } } window.robustSegmentIntersect = v; } }, window.robustSegmentIntersect);