if(!window.bigRat){window.bigRat = {};}
if(!window.bigRat.sign){window.bigRat.sign = {};}

(function (global, module, exports) {
'use strict'

var bnsign = window.bigRat.lib.bnSign

module.exports = sign

function sign(x) {
  return bnsign(x[0]) * bnsign(x[1])
}

}).call(this, window, { get exports() { if(!window.bigRat.sign) window.bigRat.sign = {}; return window.bigRat.sign; }, set exports(v) { if(typeof window.bigRat.sign === "object") { for (var k in window.bigRat.sign) { v[k] = window.bigRat.sign[k]; } } window.bigRat.sign = v; } }, window.bigRat.sign);