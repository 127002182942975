if(!window.bigRat){window.bigRat = {};}
if(!window.bigRat.div){window.bigRat.div = {};}

(function (global, module, exports) {
'use strict'

var rationalize = window.bigRat.lib.rationalize

module.exports = div

function div(a, b) {
  return rationalize(a[0].mul(b[1]), a[1].mul(b[0]))
}

}).call(this, window, { get exports() { if(!window.bigRat.div) window.bigRat.div = {}; return window.bigRat.div; }, set exports(v) { if(typeof window.bigRat.div === "object") { for (var k in window.bigRat.div) { v[k] = window.bigRat.div[k]; } } window.bigRat.div = v; } }, window.bigRat.div);