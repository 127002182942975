if(!window.bigRat){window.bigRat = {};}
if(!window.bigRat.mul){window.bigRat.mul = {};}

(function (global, module, exports) {
'use strict'

var rationalize = window.bigRat.lib.rationalize

module.exports = mul

function mul(a, b) {
  return rationalize(a[0].mul(b[0]), a[1].mul(b[1]))
}

}).call(this, window, { get exports() { if(!window.bigRat.mul) window.bigRat.mul = {}; return window.bigRat.mul; }, set exports(v) { if(typeof window.bigRat.mul === "object") { for (var k in window.bigRat.mul) { v[k] = window.bigRat.mul[k]; } } window.bigRat.mul = v; } }, window.bigRat.mul);