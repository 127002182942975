if(!window.ratVec){window.ratVec = {};}
if(!window.ratVec.muls){window.ratVec.muls = {};}

(function (global, module, exports) {
'use strict'

var rat = window.bigRat
var mul = window.bigRat.mul

module.exports = muls

function muls(a, x) {
  var s = rat(x)
  var n = a.length
  var r = new Array(n)
  for(var i=0; i<n; ++i) {
    r[i] = mul(a[i], s)
  }
  return r
}

}).call(this, window, { get exports() { if(!window.ratVec.muls) window.ratVec.muls = {}; return window.ratVec.muls; }, set exports(v) { if(typeof window.ratVec.muls === "object") { for (var k in window.ratVec.muls) { v[k] = window.ratVec.muls[k]; } } window.ratVec.muls = v; } }, window.ratVec.muls);