if(!window.bigRat){window.bigRat = {};}
if(!window.bigRat.lib){window.bigRat.lib = {};}
if(!window.bigRat.lib.bnToNum){window.bigRat.lib.bnToNum = {};}

(function (global, module, exports) {
'use strict'

var sign = window.bigRat.lib.bnSign

module.exports = bn2num

//TODO: Make this better
function bn2num(b) {
  var l = b.length
  var words = b.words
  var out = 0
  if (l === 1) {
    out = words[0]
  } else if (l === 2) {
    out = words[0] + (words[1] * 0x4000000)
  } else {
    for (var i = 0; i < l; i++) {
      var w = words[i]
      out += w * Math.pow(0x4000000, i)
    }
  }
  return sign(b) * out
}

}).call(this, window, { get exports() { if(!window.bigRat.lib.bnToNum) window.bigRat.lib.bnToNum = {}; return window.bigRat.lib.bnToNum; }, set exports(v) { if(typeof window.bigRat.lib.bnToNum === "object") { for (var k in window.bigRat.lib.bnToNum) { v[k] = window.bigRat.lib.bnToNum[k]; } } window.bigRat.lib.bnToNum = v; } }, window.bigRat.lib.bnToNum);