if(!window.bigRat){window.bigRat = {};}
if(!window.bigRat.toFloat){window.bigRat.toFloat = {};}

(function (global, module, exports) {
'use strict'

var bn2num = window.bigRat.lib.bnToNum
var ctz = window.bigRat.lib.ctz

module.exports = roundRat

// Round a rational to the closest float
function roundRat (f) {
  var a = f[0]
  var b = f[1]
  if (a.cmpn(0) === 0) {
    return 0
  }
  var h = a.abs().divmod(b.abs())
  var iv = h.div
  var x = bn2num(iv)
  var ir = h.mod
  var sgn = (a.negative !== b.negative) ? -1 : 1
  if (ir.cmpn(0) === 0) {
    return sgn * x
  }
  if (x) {
    var s = ctz(x) + 4
    var y = bn2num(ir.ushln(s).divRound(b))
    return sgn * (x + y * Math.pow(2, -s))
  } else {
    var ybits = b.bitLength() - ir.bitLength() + 53
    var y = bn2num(ir.ushln(ybits).divRound(b))
    if (ybits < 1023) {
      return sgn * y * Math.pow(2, -ybits)
    }
    y *= Math.pow(2, -1023)
    return sgn * y * Math.pow(2, 1023 - ybits)
  }
}

}).call(this, window, { get exports() { if(!window.bigRat.toFloat) window.bigRat.toFloat = {}; return window.bigRat.toFloat; }, set exports(v) { if(typeof window.bigRat.toFloat === "object") { for (var k in window.bigRat.toFloat) { v[k] = window.bigRat.toFloat[k]; } } window.bigRat.toFloat = v; } }, window.bigRat.toFloat);