if(!window.twoSum){window.twoSum = {};}

(function (global, module, exports) {
"use strict"

module.exports = fastTwoSum

function fastTwoSum(a, b, result) {
	var x = a + b
	var bv = x - a
	var av = x - bv
	var br = b - bv
	var ar = a - av
	if(result) {
		result[0] = ar + br
		result[1] = x
		return result
	}
	return [ar+br, x]
}
}).call(this, window, { get exports() { if(!window.twoSum) window.twoSum = {}; return window.twoSum; }, set exports(v) { if(typeof window.twoSum === "object") { for (var k in window.twoSum) { v[k] = window.twoSum[k]; } } window.twoSum = v; } }, window.twoSum);