if(!window.bigRat){window.bigRat = {};}
if(!window.bigRat.lib){window.bigRat.lib = {};}
if(!window.bigRat.lib.ctz){window.bigRat.lib.ctz = {};}

(function (global, module, exports) {
'use strict'

var db = window.doubleBits
var ctz = window.bitTwiddle.countTrailingZeros

module.exports = ctzNumber

//Counts the number of trailing zeros
function ctzNumber(x) {
  var l = ctz(db.lo(x))
  if(l < 32) {
    return l
  }
  var h = ctz(db.hi(x))
  if(h > 20) {
    return 52
  }
  return h + 32
}

}).call(this, window, { get exports() { if(!window.bigRat.lib.ctz) window.bigRat.lib.ctz = {}; return window.bigRat.lib.ctz; }, set exports(v) { if(typeof window.bigRat.lib.ctz === "object") { for (var k in window.bigRat.lib.ctz) { v[k] = window.bigRat.lib.ctz[k]; } } window.bigRat.lib.ctz = v; } }, window.bigRat.lib.ctz);