if(!window.bigRat){window.bigRat = {};}

(function (global, module, exports) {
'use strict'

var isRat = window.bigRat.isRat
var isBN = window.bigRat.lib.isBn
var num2bn = window.bigRat.lib.numToBn
var str2bn = window.bigRat.lib.strToBn
var rationalize = window.bigRat.lib.rationalize
var div = window.bigRat.div

module.exports = makeRational

function makeRational(numer, denom) {
  if(isRat(numer)) {
    if(denom) {
      return div(numer, makeRational(denom))
    }
    return [numer[0].clone(), numer[1].clone()]
  }
  var shift = 0
  var a, b
  if(isBN(numer)) {
    a = numer.clone()
  } else if(typeof numer === 'string') {
    a = str2bn(numer)
  } else if(numer === 0) {
    return [num2bn(0), num2bn(1)]
  } else if(numer === Math.floor(numer)) {
    a = num2bn(numer)
  } else {
    while(numer !== Math.floor(numer)) {
      numer = numer * Math.pow(2, 256)
      shift -= 256
    }
    a = num2bn(numer)
  }
  if(isRat(denom)) {
    a.mul(denom[1])
    b = denom[0].clone()
  } else if(isBN(denom)) {
    b = denom.clone()
  } else if(typeof denom === 'string') {
    b = str2bn(denom)
  } else if(!denom) {
    b = num2bn(1)
  } else if(denom === Math.floor(denom)) {
    b = num2bn(denom)
  } else {
    while(denom !== Math.floor(denom)) {
      denom = denom * Math.pow(2, 256)
      shift += 256
    }
    b = num2bn(denom)
  }
  if(shift > 0) {
    a = a.ushln(shift)
  } else if(shift < 0) {
    b = b.ushln(-shift)
  }
  return rationalize(a, b)
}

}).call(this, window, { get exports() { if(!window.bigRat) window.bigRat = {}; return window.bigRat; }, set exports(v) { if(typeof window.bigRat === "object") { for (var k in window.bigRat) { v[k] = window.bigRat[k]; } } window.bigRat = v; } }, window.bigRat);