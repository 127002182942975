if(!window.bigRat){window.bigRat = {};}
if(!window.bigRat.cmp){window.bigRat.cmp = {};}

(function (global, module, exports) {
'use strict'

module.exports = cmp

function cmp(a, b) {
    return a[0].mul(b[1]).cmp(b[0].mul(a[1]))
}

}).call(this, window, { get exports() { if(!window.bigRat.cmp) window.bigRat.cmp = {}; return window.bigRat.cmp; }, set exports(v) { if(typeof window.bigRat.cmp === "object") { for (var k in window.bigRat.cmp) { v[k] = window.bigRat.cmp[k]; } } window.bigRat.cmp = v; } }, window.bigRat.cmp);